<template lang="pug">
	section#options
		.subOption
			p.subTitle Buscar por Unidades
			.search
				input(placeholder="Digite o nome, endereço ou cidade ou Estado do Ponto de venda" v-model="search")
				.searchIcon
					img(src="@images/paginas/onde-encontrar/icone-busca.png")
			.map(ref='map')
			.filter
				.uf
					span Estado
					select(v-model="ufId" required)
						option(disabled selected :value="null") UF
						option(v-for="state in ufs" :value="state") {{state}}
				.separator
				.results
					span <strong>{{lista.length}} resultados</strong> encontrados
			ul.units
				li(v-for="cliente in lista")
					p(v-if="cliente.name").name {{cliente.name}}
					p(v-if="cliente.start_activities").text <strong>Início das Atividades: </strong> {{formatDate(cliente.start_activities)}}
					p(v-if="cliente.telephone").text <strong>Contato: </strong> {{cliente.telephone}}
						a(:href="`https://wa.me/${cliente.telephone.replaceAll(/[() -]/g, '')}`")
							span(v-if="cliente.is_whats")
								img(src="@images/whatsapp.png").isWhats
					p(v-if="cliente.cnpj").text <strong>CNPJ: </strong> {{cliente.cnpj}}
					p(v-if="cliente.cad_icms").text <strong>Inscrição Estadual: </strong> {{cliente.cad_icms}}
					p(v-if="cliente.address").text <strong>Endereço: </strong> {{cliente.address}}, {{cliente.number}} {{cliente.complement}}
					p(v-if="cliente.city").text <strong>Cidade: </strong> {{cliente.city}}
					p(v-if="cliente.uf").text <strong>Estado: </strong> {{cliente.uf}}
					p(v-if="cliente.cep").text <strong>CEP: </strong> {{cliente.cep}}
					hr
</template>

<script>

import dict from '@/data/dicionarioEstado.js'

export default {
	name: "section-options",
	data() {
		return {
			map: null,
			ufs: [],
			ufId: null,
			search: '',
			lista: [],
			apiMapbox: '',
		}
	},
	methods: {
		getUfs() {
			this.$axios.get('ibge-api')
			.then(response => {
				this.ufs = response.data.filter(option => {
					if (option == 'PR'|| option == 'MS' || option == 'SC') return true
				})
			})
		},
		formatDate(date) {
            const objData = new Date(date)
            let formatted = `${objData.getDate()}/${objData.getMonth()}/${objData.getFullYear()}`
            return formatted
		},
		createMap() {
			for (let setting of this.settings) {
                if (setting.attribute === 'apiMapbox') this.apiMapbox = setting.value;
            }
			mapboxgl.accessToken = `${this.apiMapbox}`
			this.map = new mapboxgl.Map({
				container: this.$refs.map,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: [-52.461213, -24.0427725],
				zoom: 7
			})
			this.clientes.forEach(cliente => {
				this.$set(cliente, 'marker', new mapboxgl.Marker({ color: '#5d9e2f', anchor: 'center' })
				.setLngLat([cliente.longitude, cliente.latitude])
				.addTo(this.map))
			})
			this.filterList()
		},
		searchOptions(option) {
			let formattedOption = option.toLowerCase()
			if (!this.search && this.ufId) return false
			if (formattedOption.includes(this.search.toLowerCase()))
				return true
			return false
		},
		filterList() {
			const lista = this.units.filter(option => {
				if (
					this.searchOptions(option.name) ||
					this.searchOptions(option.address) ||
					this.searchOptions(option.city) ||
					this.searchOptions(option.estado) || 
					this.searchOptions(option.uf) || 
					this.ufId && (this.ufId.toLowerCase() == option.uf.toLowerCase())
				) {
					if (option.marker.addTo !== undefined) {
						option.marker.addTo(this.map)
					}
					return true
				}
				if (option.marker.remove !== undefined) {
					option.marker.remove()
				}
				return false
			})
			if (this.map && lista.length !== 0) {
				let lat = lista.reduce((acc, curr) => acc + +curr.latitude, 0) / lista.length;
				let long = lista.reduce((acc, curr) => acc + +curr.longitude, 0) / lista.length;
				this.map.setCenter([long, lat]);
			}
			this.lista = lista
		},
	},
	watch: {
		ufId: function () {
			this.filterList()
		},
		search: function () {
			this.filterList()
		},
		settings: function () {
            setTimeout(() => this.createMap(), 400)
        }
	},
	created() {
		this.$store.dispatch('fetchSettings')
		this.$store.dispatch('fetchUnits')
	},
	mounted() {
		this.getUfs()
	},
	computed: {
		clientes() {
			this.units = this.$store.state.units.map(cliente => {
				return {...cliente, marker: cliente.marker || {}, estado: dict[cliente.uf.toUpperCase()]}
			})
			return this.units
		},
		settings() {
			return this.$store.state.settings
		}
	},
}
</script>

<style lang="stylus" scoped src="./Unidades.styl"></style>